import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import MediaGalleryCardHolder from "../components/MediaGalleryCardHolder";
import HomepageHero from "../components/HomepageHero";

const InstagramPage = () => {
  const imagesQuery = useStaticQuery(graphql`
    {
      instagramPage: craftQafInstagramQafInstagramEntry {
        descriptionSeo
        titleSeo
        showHeroBanner
        showGallery
        bannerImage {
          url
        }
        mp4Video {
          url
        }
        webmVideo {
          url
        }
        qafGallery {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 1440) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 1440) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = imagesQuery.instagramPage;

  const titleSeo = data.titleSeo;
  const descriptionSeo = data.descriptionSeo;

  const showHeroBanner = data.showHeroBanner;
  const showGallery = data.showGallery;

  const mp4Url = data?.mp4Video?.length ? data.mp4Video[0].url : null;
  const webmUrl = data?.webmVideo?.length ? data.webmVideo[0].url : null;
  const heroImageUrl = data?.bannerImage?.length ? data.bannerImage[0].url : null;

  const imageList = data?.qafGallery?.length ? data.qafGallery : [];

  return (
    <Layout backgroundColor={"var(--bg-color)"} skipHeader headerBlack={true} title={titleSeo} SEODescription={descriptionSeo}>
      {showHeroBanner ? <HomepageHero mp4Url={mp4Url} webmUrl={webmUrl} heroImageUrl={heroImageUrl} /> : null}
      {showGallery ? <MediaGalleryCardHolder cards={imageList} /> : null}
    </Layout>
  );
};

export default InstagramPage;
